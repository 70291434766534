  @keyframes fadeInDown {
    from {
        opacity: 0;
        -webkit-transform: translateY(-20px);
        transform: translateY(-20px);
    }
  
    to {
        opacity: 1;
        -webkit-transform: translateY(0);    
        transform: translateY(0);
    }
  }
  
  .fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
  }

.animated {
  -webkit-animation-duration: 1.5s;
  animation-duration: 1.5s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.animated.fast {
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
  }

@media (prefers-reduced-motion) {
  .animated {
    -webkit-animation: unset !important;
    animation: unset !important;
    -webkit-transition: none !important;
    transition: none !important;
  }
}
