// Styling for the masthead
header.masthead {
  text-align: center;
  color: $gray-300;
  @include background-cover;
    .intro-text {
    padding-top: 50px;
    padding-bottom: 50px;
    margin-top: 50px;
    .intro-heading {
      font-size: 3em;
      font-weight: 400;
      line-height: 50px;
      @include title-font;
  }
  .intro-sub {
    font-size: 1em;
    font-style: italic;
    line-height: 22px;
    margin-right: 10%;
    text-align: right;
    @include sub-font;
    }
  }
}

@media(min-width:768px) {
  header.masthead {
    .intro-text {
      padding-top: 50px;
      padding-bottom: 50px;
      .intro-heading {
        font-size: 4em;
        font-weight: 600;
        line-height: 85px;
        @include title-font;
      }
      .intro-sub {
        font-size: 1.3em;
        font-style: italic;
        line-height: 50px;
        margin-right: 10%;
        text-align: right;
        @include sub-font;
      }
    }
  }
}
