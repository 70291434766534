// Global styling
body {
  overflow-x: hidden;
  @include body-font;
  background-color: $background;
}

p {
  line-height: 1.75;
  color: $gray-300;
  margin-bottom: 0.1rem;
}

a {
  -webkit-transition: all 0.4s ease-in-out;
	-moz-transition:    all 0.4s ease-in-out;
	-ms-transition:     all 0.4s ease-in-out;
	-o-transition:      all 0.4s ease-in-out;
	transition:         all 0.4s ease-in-out;
  color: $primary;
  &:hover {
    color: $secondary;
    text-decoration: none;
  }
}

.text-primary {
  color: $primary !important;
}

hr {
  border-top: 1px solid rgb(80, 91, 102);
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include title-font;
  color: $primary;
  margin-top: 1rem;
  margin-bottom: 0rem;
}

dl, ol, ul {
  color: $gray-100;
}

code {
  color: $code;
}

pre {
  overflow: auto;
  background-color: $pre;
  border: 1px dashed $primary;
  padding: 4pt;
  color: $gray-100;
}

pre code {
  color: $code;
}

b,strong {
  font-weight: bolder;
  box-shadow: 0 1px 0 #fff,0 4px 0 #fff;
  letter-spacing: .1em;
  text-transform: uppercase;
  text-decoration: none;
}

blockquote {
  background: $background;
  border-left: 10px solid $primary;
  margin: 1.5em 10px;
  padding: 0.5em 10px;
  quotes: "\201C""\201D""\2018""\2019";
  font-style: italic;
}
blockquote:before {
  color: $gray-600;
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
blockquote:after {
  color: $gray-600;
  content: close-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.25em;
  vertical-align: -0.4em;
}
blockquote p {
  display: inline;
  font-size: 2em;
}

.sidebar {
  p {
    line-height: 1.5;
  }
  .avatar {
    border-radius: 100%;
    -webkit-border-radius: 100%;
    -moz-border-radius: 100%;
    border-width: 0.60em;
    border-style: solid;
    margin-right: 10px;
    margin-left: 10px;
    margin-top: 5px;
  }
}

.pagebox {
  color: $gray-600;
}

.wrapper {
  width: 100%;
  margin: 0 auto;
}

section {
  padding: 100px 0;
  h2.section-heading {
    font-size: 40px;
    margin-top: 0;
    margin-bottom: 15px;
  }
  h3.section-subheading {
    font-size: 16px;
    font-weight: 400;
    font-style: italic;
    margin-bottom: 75px;
    text-transform: none;
    @include body-font;
  }
}
@media(min-width:768px) {
  section {
    padding: 20px 0;
  }

  .wrapper {
    max-width: 1400px;
    margin: 0 auto;
  }
}

// Highlight color customization
::-moz-selection {
  background: $primary;
  text-shadow: none;
}

::selection {
  background: $primary;
  text-shadow: none;
}

img::selection {
  background: transparent;
}

img::-moz-selection {
  background: transparent;
}

// Custom button styles
.btn-secondary, .btn-dark {
  font-weight: 400;
  color: $gray-100;
  background-color: $gray-800;
  border-color: $gray-800;
  &:hover {
    background-color: $secondary;
    border-color: $secondary;
    color: $black;
    text-decoration: none;
  }
  }

// Fade-in Animation
@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
 
// Table styling
table {
  background:$gray-800;
  border-radius: 5px;
  overflow: hidden;
  width: 95%;
  margin: 0 auto;
  position: relative;
  margin-bottom: 20px;
}
table * {
  position: relative;
}
table td, table th {
  padding-left: 8px;
  padding-right: 8px;
  color: $gray-100;
}
table thead tr {
  height: 60px;
  background: $background;
}
table tbody tr {
  height: 45px;
}

// Highlighted text in code using Rogue
.highlight table td { padding: 5px; }
.highlight table pre { margin: 0; }
.highlight, .highlight .w {
  color: #d0d0d0;
  background-color: #19364E;
}
.highlight .err {
  color: #151515;
  background-color: #ac414300;
}
.highlight .c, .highlight .ch, .highlight .cd, .highlight .cm, .highlight .cpf, .highlight .c1, .highlight .cs {
  color: #505050;
}
.highlight .cp {
  color: #f4bf75;
}
.highlight .nt {
  color: #f4bf75;
}
.highlight .o, .highlight .ow {
  color: #d0d0d0;
}
.highlight .p, .highlight .pi {
  color: #d0d0d0;
}
.highlight .gi {
  color: #90a959;
}
.highlight .gd {
  color: #ac4142;
}
.highlight .gh {
  color: #6a9fb5;
  background-color: #151515;
  font-weight: bold;
}
.highlight .k, .highlight .kn, .highlight .kp, .highlight .kr, .highlight .kv {
  color: #aa759f;
}
.highlight .kc {
  color: #d28445;
}
.highlight .kt {
  color: #d28445;
}
.highlight .kd {
  color: #d28445;
}
.highlight .s, .highlight .sb, .highlight .sc, .highlight .dl, .highlight .sd, .highlight .s2, .highlight .sh, .highlight .sx, .highlight .s1 {
  color: #9dbb5c;
}
.highlight .sa {
  color: #aa759f;
}
.highlight .sr {
  color: #75b5aa;
}
.highlight .si {
  color: #8f5536;
}
.highlight .se {
  color: #8f5536;
}
.highlight .nn {
  color: #FFB369;
}
.highlight .nc {
  color: #FFB369;
}
.highlight .no {
  color: #FF6969;
}
.highlight .na {
  color: #FFB369;
}
.highlight .m, .highlight .mb, .highlight .mf, .highlight .mh, .highlight .mi, .highlight .il, .highlight .mo, .highlight .mx {
  color: #298cc8;
}
.highlight .ss {
  color: #90a959;
}
