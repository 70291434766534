// Styling for the blog section
.content {
  padding-top: 100px;
  padding-bottom: 50px;
  background-image: none;
}

.postbox {
  background: $backgroundgrey;
  padding-top: 40px;
  padding-bottom: 40px;
  padding-right: 40px;
  padding-left: 40px;
  margin-bottom: 40px;
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, 0.2) !important;
  border-radius: .25rem !important;
}